import styled from "styled-components"

export const LargeImageStyles = styled.section`
  width: 100%;
  margin-bottom: 0 !important;

  @media(max-width: 1024px) {
    padding: 0 !important;
  }

  > .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px 30px;
    background: linear-gradient(to bottom, #efecea 50%, var(--primary) 50%);

    :before {
      content: "";
      position: absolute;
      top: -3px;
      left: 0;
      width: 50%;
      border-top: 3px solid var(--primary);
    }
    
    > * {
      width: 100%;
      max-height: 600px;
      @media(min-width: 768px) {
        max-height: auto;
      }
    }

    @media(min-width: 768px) {
      padding: 0 0 80px 0px;
    }
  }
`