import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { LargeImageStyles } from './LargeImageStyles';

const LargeImage = ({ image }) => {
    const gatsbyImage = getImage(image)
    return (
        <LargeImageStyles className="section">
            <div className="container">
                <GatsbyImage image={gatsbyImage} alt={image?.description || null} />
            </div>
        </LargeImageStyles>
      )
}

export default LargeImage;